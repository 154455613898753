import {Box, Button, Container, Grid, Typography} from '@mui/material'
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import {logo, featureintro, Heromain, contactus, dots} from '../utils/images'
import React, {useEffect, useRef, useState} from 'react'
import {amber, pink, teal} from '@mui/material/colors'
import {navigationroute} from '../utils/util'
import ArticleCardOne from '../components/componentsparts/SvgCards/ArticleCardOne'
import VideoCardOne from '../components/componentsparts/SvgCards/VideoCardOne'
import VideoCardTwo from '../components/componentsparts/SvgCards/VideoCardTwo'
import {useNavigate} from 'react-router-dom'
import ToolCardOne from '../components/componentsparts/SvgCards/ToolCardOne'
import ToolCardTwo from '../components/componentsparts/SvgCards/ToolCardTwo'
import ArticleCardTwo from '../components/componentsparts/SvgCards/ArticleCardTwo'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import DuoOutlinedIcon from '@mui/icons-material/DuoOutlined'
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined'
type Props = {}

const LandingSingle = (props: Props) => {
  const navigate = useNavigate()
  const centerHeroCard = useRef<any>()
  const ArticlesContainer = useRef<any>()
  const VideosContainer = useRef<any>()
  const ToolsContainer = useRef<any>()
  const readyref = useRef<any>()
  const bubbleref = useRef<any>()
  const [currentTopic, setCurrentTopic] = useState<string>('articles')

  useEffect(() => {
    const articleslide1 = document.getElementsByClassName('articlepopup1')[0]
    const articleslide2 = document.querySelector('.articlepopup2')
    const videoslide1 = document.querySelector('.videopopup1')
    const videoslide2 = document.querySelector('.videopopup2')
    const toolslide1 = document.querySelector('.toolpopup1')
    const toolslide2 = document.querySelector('.toolpopup2')
    const landingFooter = document.querySelector(
      '#landing-single-footer'
    ) as any
    const scrollConsole = () => {
      // let centerHeroTop = centerHeroCard.current?.offsetTop || 0;
      // const posY = Math.min(window.pageYOffset / centerHeroTop, 1);
      if (ArticlesContainer.current?.getBoundingClientRect().top < 0) {
        if (VideosContainer.current?.getBoundingClientRect().top > 20) {
          setCurrentTopic('videos')
        }
        if (VideosContainer.current?.getBoundingClientRect().top < 0) {
          setCurrentTopic('tools')
        }
      } else {
        setCurrentTopic('articles')
      }
      // ArticlesContainer.current.style.transform = `translate3d(${posY * 20}% , ${posY * 40
      //     }%, 0) scale(${1 - posY * 0.2})`;
      // ArticlesContainer.current.style.opacity = `${1 - posY * 1}`;

      // VideosContainer.current.style.transform = `translate3d(${posY * 20}% , ${posY * 40
      //     }%, 0) scale(${1 - posY * 0.2})`;
      // VideosContainer.current.style.opacity = `${1 - posY * 1}`;

      // ToolsContainer.current.style.transform = `translate3d(${posY * 20}% , ${posY * 40
      //     }%, 0) scale(${1 - posY * 0.2})`;
      // ToolsContainer.current.style.opacity = `${1 - posY * 1}`;
    }

    const mouseConsole = (e: any) => {
      const landingdom = landingFooter.getBoundingClientRect()
      const angle = Math.atan2(
        e.clientY - landingdom.left / (landingdom.width / 2),
        e.clientX - landingdom.top / (landingdom.height / 2)
      )
      readyref.current.style.transform = `rotate(${angle / 6}rad)`
      const topValue = Number(landingdom.height - e.clientY)
      bubbleref.current.style.top = e.clientY - (landingdom.top - 40) + 'px'
      bubbleref.current.style.left = e.clientX - landingdom.left - 200 + 'px'
    }

    window.addEventListener('scroll', scrollConsole, true)
    landingFooter.addEventListener('mousemove', mouseConsole, true)

    return () => {
      window.removeEventListener('scroll', scrollConsole, true)
      landingFooter.addEventListener('mousemove', mouseConsole, true)
    }
  }, [])
  return (
    <div>
      {/* gradeints */}
      <div
        className="rectangle"
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'transparent',
        }}
      >
        <div className="big-gradient-circle red-anim gradient-anim">
          <svg
            width="1576"
            height="712"
            viewBox="0 0 1576 712"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="788"
              cy="356"
              rx="788"
              ry="356"
              fill="#FEDCF4"
              opacity="0.5"
            />
            <defs>
              <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(788 356) rotate(90) scale(353.577 782.637)"
              >
                <stop stop-color="#FEDCF4" />
                <stop offset="1" stop-color="#FEDDE8" stopOpacity="0.01" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="big-gradient-circle gradient-anim violet-anim delay-2">
          <svg
            width="2033"
            height="920"
            viewBox="0 0 2033 920"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="#00D690">
              <ellipse
                cx="1113"
                cy="545.5"
                rx="1036"
                ry="468.5"
                fill="#00D690"
                opacity="0.1"
              />
            </g>
            <defs>
              <filter
                id="filter0_f"
                x="0.888107"
                y="0.888107"
                width="2224.22"
                height="1089.22"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="38.0559"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1113 545.5) rotate(90) scale(468.5 1036)"
              >
                <stop stop-color="#B3B7FF" />
                <stop offset="1" stop-color="#DEDDFE" stopOpacity="0.0821499" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div
          className="big-gradient-circle gradient-anim green-anim delay-2"
          style={{animationDelay: '3s'}}
        >
          sdfsddfdsfddfdf {process.env.NODE_ENV}
          <svg
            width="2033"
            height="920"
            viewBox="0 0 2033 920"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="1016.5"
              cy="460"
              rx="1016.5"
              ry="460"
              fill="#B3B7FF"
              opacity="0.4"
            />
            <defs>
              <radialGradient
                id="paint0_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(1016.5 464.845) rotate(90) scale(452.024 998.875)"
              >
                <stop stop-color="#00D690" stopOpacity="0.162079" />
                <stop offset="1" stop-color="#00D690" stopOpacity="0.01" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
      {/* end gradients  */}
      <Container>
        <nav style={{position: 'sticky', top: 0,zIndex: 40}}>
          <Box
            sx={{
              borderBottom: 1,
              py: 2,
              px: 4,
              borderColor: 'grey.400',
              position: 'sticky',
              top: 0,
              width: '100%',
              backgroundColor: 'rgba(255,255,255,0.8)',
              zIndex: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <img src={logo} alt="logo" height={45} />              
            </Box>
            <Box>
                <Button variant='contained' disableElevation>Get started</Button>
                <Button color='primary' onClick={()=>navigate("/auth/login")}>Login</Button>
              </Box>
          </Box>
        </nav>

        <section className="relative">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  p: 0,
                  my: 5,
                }}
                ref={centerHeroCard}
              >
                <div
                  style={{
                    position: 'sticky',
                    top: '90px',
                    width: '100%',
                    paddingBottom: 0,
                    paddingTop: '5rem',
                    borderRadius: '0.8rem',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="landing_feature_img"
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: -1,
                    }}
                  />
                  <div style={{position: 'relative'}}>
                    <img
                      src={featureintro}
                      alt=""
                      style={{width: '100%', height: '100%', marginTop: 3}}
                    />
                    {/* Article popups */}
                    <Box
                      className="articlepopup1 bounceanim-slow"
                      sx={{
                        position: 'absolute',
                        top: 90,
                        right: 10,
                        width: '15rem',
                        height: '10rem',
                        borderRadius: 3,
                        opacity: currentTopic === 'articles' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                      }}
                    >
                      <ArticleCardOne />
                    </Box>
                    <Box
                      className="articlepopup2"
                      sx={{
                        position: 'absolute',
                        bottom: 80,
                        left: 10,
                        width: '15rem',
                        height: '10rem',
                        backgroundColor: 'white',
                        borderRadius: 3,
                        opacity: currentTopic === 'articles' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                        overflow: 'hidden',
                      }}
                    >
                      <ArticleCardTwo />
                    </Box>
                    {/* Video popups */}
                    <Box
                      className="videopopup1 bounceanim-slow"
                      sx={{
                        position: 'absolute',
                        top: 20,
                        left: 10,
                        width: '15rem',
                        height: '10rem',
                        borderRadius: 3,
                        opacity: currentTopic === 'videos' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                      }}
                    >
                      <VideoCardOne />
                    </Box>
                    <Box
                      className="videopopup2 bounceanim-slow"
                      sx={{
                        position: 'absolute',
                        bottom: 120,
                        right: 10,
                        width: '15rem',
                        height: '10rem',
                        backgroundColor: 'success.main',
                        borderRadius: 3,
                        opacity: currentTopic === 'videos' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                        overflow: 'hidden',
                      }}
                    >
                      <VideoCardTwo />
                    </Box>
                    {/* Tools popups */}
                    <Box
                      className="toolpopup1 bounceanim-slow"
                      sx={{
                        position: 'absolute',
                        top: 50,
                        right: 10,
                        width: '15rem',
                        height: '10rem',
                        backgroundColor: 'info.main',
                        borderRadius: 3,
                        opacity: currentTopic === 'tools' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                        overflow: 'hidden',
                      }}
                    >
                      <ToolCardOne />
                    </Box>
                    <Box
                      className="toolpopup2 bounceanim-slow delay-1"
                      sx={{
                        position: 'absolute',
                        bottom: 40,
                        left: 10,
                        width: '15rem',
                        height: '10rem',
                        backgroundColor: 'info.main',
                        borderRadius: 3,
                        opacity: currentTopic === 'tools' ? 1 : 0,
                        transition: 'all 1s ease-in-out',
                        overflow: 'hidden',
                      }}
                    >
                      <ToolCardTwo />
                    </Box>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box ml={6} mr={3} pt={18} pb={2}>
                {/* Articles */}
                <Box
                  ref={ArticlesContainer}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '100vh',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: amber[600],
                        px: 5,
                        py: 1,
                        borderRadius: 8,
                        mx: 7,
                        my: 3,
                      }}
                    >
                      <Typography
                        component="h6"
                        sx={{fontSize: '1rem', fontWeight: '600'}}
                      >
                        Articles
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 5,
                      }}
                    >
                      <h4 style={{lineHeight: 1}}>
                        <ArticleOutlinedIcon />
                      </h4>
                      <Typography
                        sx={{fontWeight: 'bold'}}
                        fontSize={16}
                        component="p"
                      >
                        Creating and sharing articles involves several key steps. 
                        First, identify your target audience and choose relevant topics. Write engaging, well-researched content with attention-grabbing headlines and clear structure. Use visuals to enhance appeal. 
                        Once completed, publish the article on platforms like blogs, websites, or social media.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 2,
                        mx: 8,
                        my: 3,
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{textTransform: 'none'}}
                        onClick={() =>
                          navigate(navigationroute('/content/articles'))
                        }
                      >
                        Explore articles
                      </Button>
                      {/* <Button color="primary" variant="outlined" sx={{ textTransform: 'none' }}>Submit article</Button> */}
                    </Box>
                  </Box>
                </Box>
                {/* Videos */}
                <Box
                  ref={VideosContainer}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: '100vh',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: amber[600],
                        px: 5,
                        py: 1,
                        borderRadius: 8,
                        mx: 7,
                        my: 3,
                      }}
                    >
                      <Typography
                        component="h6"
                        sx={{fontSize: '1rem', fontWeight: '600'}}
                      >
                        Videos
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 5,
                      }}
                    >
                      <h4 style={{lineHeight: 1}}>
                        <DuoOutlinedIcon />
                      </h4>
                      <Typography
                        sx={{fontWeight: 'bold'}}
                        fontSize={18}
                        component="h4"
                      >
                        Publish the video on platforms like YouTube, Instagram, or TikTok, and optimize titles, descriptions, and tags for better discoverability. For marketing, promote the video via social media, email campaigns, and collaborations with influencers. Track performance through video analytics to improve future content and engagement strategies.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 2,
                        mx: 8,
                        my: 3,
                      }}
                    >
                      <Button
                        color="warning"
                        variant="contained"
                        sx={{textTransform: 'none'}}
                        onClick={() =>
                          navigate(navigationroute('/content/videos'))
                        }
                      >
                        Explore videos
                      </Button>
                      {/* <Button color="warning" variant="outlined" sx={{ textTransform: 'none' }}>Submit videos</Button> */}
                    </Box>
                  </Box>
                </Box>
                {/* Tools */}
                <Box
                  ref={ToolsContainer}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    justifyContent: 'space-between',
                    minHeight: '100vh',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: amber[600],
                        px: 5,
                        py: 1,
                        borderRadius: 8,
                        mx: 7,
                        my: 3,
                      }}
                    >
                      <Typography
                        component="h6"
                        sx={{fontSize: '1rem', fontWeight: '600'}}
                      >
                        Tools
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 5,
                      }}
                    >
                      <h4 style={{lineHeight: 1}}>
                        <RoomPreferencesOutlinedIcon />
                      </h4>
                      <Typography
                        sx={{fontWeight: 'bold'}}
                        fontSize={18}
                        component="h4"
                      >
                        For marketing, leverage social media, blogs, email campaigns, and partnerships to promote the tool. Offer free trials or demos to attract users, and use analytics to track usage and gather feedback for continuous improvement.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        gap: 2,
                        mx: 8,
                        my: 3,
                      }}
                    >
                      <Button
                        color="info"
                        variant="contained"
                        sx={{textTransform: 'none'}}
                        onClick={() =>
                          navigate(navigationroute('/content/tools'))
                        }
                      >
                        Explore Tools
                      </Button>
                      {/* <Button color="info" variant="outlined" sx={{ textTransform: 'none' }}>Submit Tools</Button> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </section>

        {/* Web development */}
       {/*  <section style={{position:"relative"}}>
          <Grid container spacing={2}>
                <Grid item xs={6}>
                  hi
                  </Grid>
                <Grid item xs={6}>
                  hello
                  </Grid>
          </Grid>
        </section> */}
        {/* end Web development */}
        {/* Start Contact us */}
        <section className="relative" style={{marginTop: '12rem'}}>
          <Box sx={{position: 'absolute', top: -50, left: 0, right: 0,width:'300px',zIndex:-1}}>
          <img src={dots} alt="dots" className="dots" style={{width:'100%',height:'auto',zIndex:-1}} />
          </Box>
          <Grid container sx={{borderRadius: '1rem',background: "linear-gradient(to right,rgb(151, 71, 255),rgb(255, 214, 0))"}}>
            <Grid item xs={12} sm={6}>
              <Box sx={{width: '100%', height: '100%',display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div>
                <Typography
                  variant="h4"
                  component="h4"
                  sx={{
                    color: 'white',
                    fontFamily: "'Domine', serif !important",
                    my: 3,
                  }}
                >
                  Come and join us
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"  
                  sx={{
                    color: 'white',
                    my: 3,
                  }}
                >
                  We would love to hear from you
                </Typography>
              <Button
                color="warning"
                variant="contained"
                sx={{textTransform: 'none'}}
                onClick={() => navigate(navigationroute('/contact-us'))}
              >
                Contact us
              </Button>
              </div>
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{width: '100%', height: '100%'}} className="landing-single">
                <div className='image-container'>
                <img
                  src={contactus}
                  alt="img"
                  // style={{width: '100%', height: 'auto',objectFit:"cover",padding:"2rem"}}
                  className='image'
                /></div>
              </Box>
            </Grid>
          </Grid>
        </section>
        {/* end Contact us */}
        <section className="margin-bottom-negative relative">
          <div className="circular-video-section">
            {/* <img src="https://recruit.clidfsdphdires.co/img/circular-image.png" alt="imagetag" /> */}
            <div className="alias-img relative">
              <div className="round-thumb-block" style={{zIndex: -1}}>
                <div className="rotate">
                  <div
                    style={{backgroundColor: 'red', borderRadius: '100%'}}
                    className="counter one"
                  />
                  <div
                    style={{backgroundColor: 'green', borderRadius: '100%'}}
                    className="counter two"
                  />
                  <div
                    style={{backgroundColor: 'yellow', borderRadius: '100%'}}
                    className="counter three"
                  />
                  <div
                    style={{backgroundColor: 'purple', borderRadius: '100%'}}
                    className="counter four"
                  />
                </div>
              </div>
              <Box
                sx={{
                  postion: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 90,
                  pt: '4rem',
                }}
              >
                <img
                  src={Heromain}
                  alt="img"
                  style={{width: '300px', height: 'auto', zIndex: 200}}
                />
              </Box>
            </div>
          </div>
        </section>
        <section style={{position: 'relative'}}>
          <Box
            sx={{
              minHeight: '80vh',
              backgroundColor: 'primary.main',
              mt: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              position: 'relative',
              borderRadius: 1,
            }}
            id="landing-single-footer"
          >
            <Box sx={{position: 'relative'}}>
              <Typography
                component="h4"
                variant="h4"
                sx={{fontFamily: "'Domine', serif !important", my: 3}}
              >
                See DND how works?
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  top: -30,
                  left: 0,
                  px: 3,
                  py: 1,
                  backgroundColor: 'white',
                  color: 'warning.main',
                  borderRadius: 5,
                  fontWeight: 'bold',
                }}
                ref={readyref}
              >
                Ready to get
              </Box>
            </Box>
            <Box sx={{display: 'flex', gap: 3, position: 'relative'}}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => navigate(navigationroute('/auth/login'))}
                disableElevation
              >
                Login / Register
              </Button>
              <Box
                sx={{
                  position: 'absolute',
                  top: -5,
                  right: -5,
                  width: '0.8rem',
                  height: '0.8rem',
                  backgroundColor: pink[600],
                  borderRadius: '100%',
                }}
                className="pinganim-infinite"
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: amber[600],
                borderRadius: '100%',
                top: 20,
                left: 20,
                width: '5rem',
                height: '5rem',
                boxShadow: 2,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: amber[600],
                borderRadius: '100%',
                bottom: 20,
                right: 20,
                width: '5rem',
                height: '5rem',
                boxShadow: 2,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: teal['A200'],
                borderRadius: '100%',
                top: 20,
                left: 20,
                width: '2rem',
                height: '2rem',
                boxShadow: 2,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: teal['A200'],
                borderRadius: '100%',
                bottom: 20,
                right: 20,
                width: '2rem',
                height: '2rem',
                boxShadow: 2,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                backgroundColor: teal['A200'],
                borderRadius: '100%',
                bottom: 20,
                right: 20,
                width: '2rem',
                height: '2rem',
              }}
              ref={bubbleref}
            />
          </Box>
          <Box
            sx={{
              p: 3,
              color: 'white',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box className="backgroundbgfooter">All rights are reserved</Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                gap: 2,
              }}
            >
              <Box sx={{my: 'auto'}}>
                <Typography
                  component="h6"
                  variant="body2"
                  color="primary"
                  align="justify"
                  onClick={() => navigate(navigationroute('/report'))}
                >
                  Report
                </Typography>
              </Box>
              <Box
                className="footer_social"
                sx={{
                  backgroundColor: 'white',
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '100%',
                  postion: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TwitterIcon
                  fontSize="medium"
                  color="success"
                  sx={{margin: 'auto', color: 'white'}}
                />
              </Box>
              <Box
                className="footer_social"
                sx={{
                  backgroundColor: 'white',
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '100%',
                  postion: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FacebookRoundedIcon
                  fontSize="medium"
                  sx={{margin: 'auto', color: 'white'}}
                />
              </Box>
              <Box
                className="footer_social"
                sx={{
                  backgroundColor: 'white',
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '100%',
                  postion: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InstagramIcon
                  fontSize="medium"
                  sx={{margin: 'auto', color: 'white'}}
                />
              </Box>
              <Box
                className="footer_social"
                sx={{
                  backgroundColor: 'white',
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '100%',
                  postion: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LinkedInIcon
                  fontSize="medium"
                  sx={{margin: 'auto', color: 'white'}}
                />
              </Box>
            </Box>
          </Box>
        </section>
      </Container>
    </div>
  )
}

export default LandingSingle
