import React, {useState, useTransition} from 'react'
import {
  Grid,
  Paper,
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  Link,
  Grow,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {authLogin} from '../api/AuthMutations'
import {AlertToast} from '../components'
import {ALERT_ERROR, ALERT_SUCCESS, LOGIN_GATEWAY} from '../constants/constants'
import {sleep} from '../utils/util'

const Login = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [loginError, setLoginError] = useState<boolean>(false)
  const [errormsg, setErrormsg] = useState<string>('')
  const {
    mutate: LoginMutation,
    isPending: isLoading,
    isSuccess,
  } = useMutation({mutationKey: ['login'], mutationFn: authLogin})
  // Define your query key
  const queryKey = ['login']
  // Get the cached data
  const cachedData:{data: string}|undefined = queryClient.getQueryData(queryKey)
  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .max(20, 'Must be 20 characters or less')
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        // )
        .required('Required'),
    }),

    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      LoginMutation(values, {
        onSuccess: ({data}) => {
          if ('errors' in data) {
            setLoginError(true)
            const isCredError =
              data.errors[0].message.match('LoginPayload.token')
            if (isCredError) {
              setErrormsg('Invalid credentials!')
            }
          } else {
            queryClient.setQueryData(['login'], () => {
              return data
            })
            const {
              data: {
                login: {token, user, refreshExpiresIn},
              },
            } = data
            if (!user.isActive) {
              setErrormsg('User account is disabled or not activated!')
              setLoginError(true)
            } else {
              localStorage.setItem('authToken', token)
              sleep(2000).then(() =>
                navigate(LOGIN_GATEWAY, {state: {from: 'login_success'}})
              )
            }
          }
        },
        onError: () => {
          setErrormsg('NetWork error! Please try again!')
          setLoginError(true)
        },
      })
      // sleep(3000).then(() => formik.setSubmitting(false));
    },
  })

  if (cachedData) {
    const {data} = cachedData
    if(data){
      navigate(-1)
    }
  }
  return (
    <div className="relative" style={{width: '100%', overflow: 'hidden'}}>
      <Grow in={true}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{minHeight: '100vh'}}
          spacing={2}
        >
          <Grid item md={4} lg={4} className="relative logindotanimtrigger">
            <Box
              boxShadow={5}
              borderRadius={4}
              sx={{overflow: 'hidden', backgroundColor: 'white'}}
            >
              <Stack
                spacing={6}
                p={6}
                className="loginbox"
                sx={{overflow: 'hidden'}}
              >
                <Box
                  sx={{position: 'relative', zIndex: 3, margin: 'auto'}}
                  alignContent="center"
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'white',
                      fontStyle: 'italic',
                      '&:after': {
                        content: '""',
                        width: 100,
                        height: 50,
                        position: 'absolute',
                        display: 'inline-block',
                        backgroundColor: '#aa00ff',
                        zIndex: -10,
                        transform: 'skew(-3deg)',
                        top: '-0.5rem',
                        bottom: '-0.25rem',
                        left: '-1.2rem',
                        right: '-0.25rem',
                        borderRadius: '0.3rem',
                      },
                    }}
                  >
                    Login
                  </Typography>
                </Box>
                <Box
                  component="div"
                  style={{
                    width: 20,
                    height: 20,
                    background: '#ffa726',
                    position: 'absolute',
                    right: -10,
                    top: -40,
                    borderRadius: '100%',
                    opacity: 0.8,
                    transition: 'all 2s ease-in-out',
                  }}
                  className="loginanimdot"
                />
                <Box sx={{width: '100%'}}>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      helperText={formik.errors?.email || ''}
                      id="email-field"
                      label="Email"
                      name="email"
                      fullWidth
                      autoFocus
                      sx={{mt: 1}}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={
                        Boolean(formik.touched.email && formik.errors.email) ||
                        false
                      }
                    />
                    <TextField
                      helperText={formik.errors?.password || ''}
                      type="password"
                      id="password-field"
                      label="Password"
                      name="password"
                      fullWidth
                      sx={{
                        mt: 4,
                        // '&.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root': {
                        // fontSize:'0.5rem',}
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={
                        Boolean(
                          formik.touched.password && formik.errors.password
                        ) || false
                      }
                    />
                    <LoadingButton
                      fullWidth
                      loading={isLoading}
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        mt: 4,
                        borderRadius: 1.5,
                        px: 5,
                        textTransform: 'inherit',
                      }}
                      type="submit"
                    >
                      Login
                    </LoadingButton>
                  </form>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Link
                      component="p"
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="primary.main"
                      onClick={() => {
                        navigate('/auth/register')
                      }}
                    >
                      Create new account?
                    </Link>
                    <Link
                      sx={{
                        mt: 2,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      color="secondary.darkxl"
                      onClick={() => {
                        navigate('/auth/forgotpassword')
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Box>
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        {/* <div className="authbubble bigbubblebounce" />
            <div className="authbubble smallbubblebounce" />
            <div className="authbubble bigbubblebouncetop" />
            <div className="authbubble smallbubblebouncetop" />             */}
      </Grow>
      <AlertToast
        title={errormsg}
        level={ALERT_ERROR}
        isOpen={loginError}
        onClose={() => setLoginError(false)}
      />
    </div>
  )
}

export default Login
