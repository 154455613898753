import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Chip} from '@mui/material'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: 260,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{borderRight: 1, borderColor: 'divider'}}
      >
        <Tab label="Frontend" {...a11yProps(0)} />
        <Tab label="Mobile" {...a11yProps(1)} />
        <Tab label="Database" {...a11yProps(2)} />
        <Tab label="Backend" {...a11yProps(3)} />
        <Tab label="Digital Marketing" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Chip label="HTML5" />
        <Chip label="CSS3" />
        <Chip label="JAVASCRIPT" />
        <Chip label="JQUERY" />
        <Chip label="REACT" />
        <Chip label="ANGULAR" />
        <Typography>
          Our experienced front-end developers are more than capable of offering
          web development solutions that are innovative, scalable and
          responsive. We help businesses to grow by providing innovative digital
          solutions that align with their vision.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Chip label="REACTNATIVE" />
        <Typography>
          We provide custom mobile application solutions that can help your business reach new heights by providing you a secure and scalable solution. Aren’t sure which technology is the best fit for your solution ? We’ll help figure you out !
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Chip label="SQL" />
        <Chip label="MYSQL" />
        <Chip label="POSTGRESQL" />
        <Chip label="MONGODB" />
        <Chip label="CASSANDRADB" />
        <Chip label="COCKROACHDB" />
        <Chip label="AWS DYNAMODB" />
        <Typography>
          Our back-end developers are well-versed in providing robust and efficient database management systems, giving business the power to lead. Our team holds an in-depth understanding of both - open and closed source databases.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Chip label="NODEJS" />
        <Chip label="NESTJS" />
        <Chip label="NEXTJS" />
        <Chip label="PYTHON" />
        <Chip label="PYTHON-DJANGO" />
        <Chip label="PHP" />
        <Chip label=".NET" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Chip label="SEO" />
        <Chip label="GOOGLE ADS" />
        <Chip label="FACEBOOK ADS" />
        <Chip label="GA4 AND GTM SETUP" />
        <Chip label="SHOPPING CAMPAIGNS" />
        <Chip label="YOUTUBE PROMOTIONS" />
        <Typography>
          Our Digital Marketing services are aimed towards helping out businesses in increasing leads and sales and integrate the brand’s image with the most effective marketing strategies. We’ve partnered with companies across the globe to achieve their conversion goals.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
  )
}
