import * as React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import {NavLink, useNavigate} from 'react-router-dom'
import {purple} from '@mui/material/colors'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import {logOut} from '../../api/AuthMutations'

export default function NavbarMenu() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [authdata, setAuthdata] = React.useState<any>(() => {
    if (localStorage.getItem('authdata')) {
      const authtoparse = localStorage.getItem('authdata') || {}
      return JSON.parse(authtoparse)
    } else {
      return null
    }
  })

  React.useEffect(() => {
    const authtoparse: any = localStorage.getItem('authdata') || {}
    const authdata = JSON.parse(authtoparse)
    setAuthdata(authdata)
  }, [localStorage.getItem('authdata')])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  console.log(authdata)
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'end',
          px: 2,
        }}
      >
        <Typography sx={{minWidth: 100}} variant="body2">
          {authdata?.fullName || 'username'} (
          {authdata?.isSuperuser
            ? 'admin'
            : authdata?.isStaff
            ? 'author'
            : authdata?.isActive
            ? 'user'
            : 'freezed'}
          )
        </Typography>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{width: 32, height: 32}}>M</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {/* <MenuItem sx={{':hover':{backgroundColor: purple[100], color: purple[900]}}}>
          <Person3Icon fontSize="small" /> Profile
        </MenuItem> */}
        <MenuItem
          component={NavLink}
          to="/user/library"
          sx={{':hover': {backgroundColor: purple[100], color: purple[900]}}}
        >
          <ListItemIcon>
            <ImportContactsIcon fontSize="small" />
          </ListItemIcon>
          Collections
        </MenuItem>
        <Divider />
        <MenuItem
          component={NavLink}
          to="/user/plan/history"
          sx={{':hover': {backgroundColor: purple[100], color: purple[900]}}}
        >
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          Plan history (subscription)
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/pricing"
          sx={{':hover': {backgroundColor: purple[100], color: purple[900]}}}
        >
          <ListItemIcon>
            <PaidOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Pricing
        </MenuItem>
        <MenuItem
          component={NavLink}
          to="/user/settings"
          sx={{':hover': {backgroundColor: purple[100], color: purple[900]}}}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          sx={{':hover': {backgroundColor: purple[100], color: purple[900]}}}
          onClick={logOut}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
