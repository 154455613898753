/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import content from '../assets/images/content-1.png'
import logo from '../assets/images/logo.png'
import Yellowbucket from '../assets/images/settings/yellowbucket.svg'
import defaultuser from '../assets/images/settings/defaultuser.svg'
import Usertyping from '../assets/images/settings/usertyping.svg'
import Userforcontact from '../assets/images/settings/userforcontact.svg'
import linestroke from '../assets/images/line-stroke.svg'
import ThumbUp from '../assets/images/thumb_up.svg'
import FavoriteLight from '../assets/images/Favorite_light.svg'
import Views from '../assets/images/views.svg'
import Checkmark from '../assets/images/checkmark.svg'
import featureintro from '../assets/images/feature-intro.png'
import Heromain from '../assets/images/hero-main.png'
import noresultsfound from '../assets/images/noresults.png'
import contactus from '../assets/images/contactus.png'
import dots from '../assets/images/dots.png'
export {
  content,
  logo,
  defaultuser,
  featureintro,
  Usertyping,
  Userforcontact,
  linestroke,
  ThumbUp,
  FavoriteLight,
  Views,
  Checkmark,
  Yellowbucket,
  Heromain,
  noresultsfound,
  contactus,
  dots,
}
