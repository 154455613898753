import WaitLoader from '../../components/shared/Loaders/WaitLoader';
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { isEmpty, sleep } from '../../utils/util';
import { ARTICLES_URL } from '../../constants/constants';
import { fetchQuery } from '../../api/ContentQueries';
import { meQuery } from '../../api/graphql/Queries';

type Props = {}

const LoginSuccess = (props: Props) => {
    const location:any = useLocation();
    const [gateopen, setGetopen] = useState(false);
    sleep(1000).then(() => {
        try{
           fetchQuery(meQuery,{}).then((data) => {
            const {me} = data;
            console.log(data,"worls")
            localStorage.setItem("authdata",JSON.stringify(me||{}))
            setGetopen(true)
        }) 
        }catch(err){
            setGetopen(false)
            console.log("error",err)
        }
        
    })
    

    if (!isEmpty(location.state) && gateopen) {
        if (location.state.from === "login_success") {
            return <Navigate to={ARTICLES_URL} replace={true} />
        }
    }
    return (
        <Box display="flex" alignItems="center" alignContent="center" justifyContent="center" sx={{ height: '100vh' }}>
            <WaitLoader />
        </Box>
    )
}

export default LoginSuccess;